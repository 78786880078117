// import blank from "../images/blank.png";
import i1 from "../images/recent/1toafBK2023.jpg";
import i2 from "../images/recent/2timeMachine.jpg";
import i3 from "../images/recent/3la5025.jpg";
import i4 from "../images/recent/4Strangeparadise3.jpg";
import i5 from "../images/recent/5Strangeparadise3detail.jpg";
import i6 from "../images/recent/6Woollahra2.jpg";
import i7 from "../images/recent/7Woollahr2detials.jpg";
import i8 from "../images/recent/8paddo2.jpg";
import i9 from "../images/recent/9theDinnerParty.jpg";
import i10 from "../images/recent/10newYorkersSummer.jpg";
import i11 from "../images/recent/11campcove.jpg";
import i12 from "../images/recent/12familyDinner.jpg";
import i13 from "../images/recent/13Cityofangels.jpg";
import i14 from "../images/recent/14Cityofangelsdetail.jpg";
import i15 from "../images/recent/15theFilmSet2.jpg";
import i16 from "../images/recent/16studioNov2023.jpg";
import i17 from "../images/recent/17therestaurant.jpg";
import i18 from "../images/recent/18toaf2024.jpg";
import i19 from "../images/recent/19redfern.jpg";
import i20 from "../images/recent/20manly3.jpg";
import i21 from "../images/recent/21gym.jpg";
import i22 from "../images/recent/22parisminseries.jpg";
import i23 from "../images/recent/23tribecaminiseries.jpg";
import i24 from "../images/recent/24inflatablelips.jpg";
import i25 from "../images/recent/25bondisummers5.jpg";
import i26 from "../images/recent/26paris.jpg";
import i27 from "../images/recent/27washingtonSq.jpg";
import i28 from "../images/recent/28surryhills.jpg";
import i29 from "../images/recent/29paddington7.jpg";
import i30 from "../images/recent/30tokyo2174.jpg";
import i31 from "../images/recent/31inflatablehulk.jpg";
import i32 from "../images/recent/32theOptimist2.jpg";
import i33 from "../images/recent/33newYorkersWinter.jpg";
import i34 from "../images/recent/34woosterAndPrince.jpg";
import i35 from "../images/recent/35malibu.jpg";
import i36 from "../images/recent/36euroSummer.jpg";
import i37 from "../images/recent/37thredbo4.jpg";
import i38 from "../images/recent/38soho2053.jpg";
import i39 from "../images/recent/39homeHeart.jpg";
import i40 from "../images/recent/40kuttibeach2.jpg";
import i41 from "../images/recent/41soloshow2017.jpg";
import i42 from "../images/recent/42theoptimist.jpg";
import i43 from "../images/recent/43october.jpg";
import i44 from "../images/recent/44fortGreene.jpg";
import i45 from "../images/recent/45chelseaNYC.jpg";

/*
imageCollection is an [array,array,array] of {objects:objects,objects:objects}, structured as follows:
{
  image: importedImage
  title:"Mona Lisa"
  description: "Oil on Canvas, 2017 etc",
  alt:"Painting of a woman with an ambiguous smile, with a bleak landscape behind her",
  sold: true,
  price: "6300"

}
image must be imported from it's relative location at the top of this script.
title and description will display beneath each image,
alt is for screen readers for visually impaired people, put a more detailed description here.
sold is optional, true will add a little red "-SOLD" next to the title
price is optional, will add a price to the label
*/

const imageCollection = [
  // { image: blank, alt: "blank" },

  {
    image: i1,
    title: "The Other Art Fair- Brooklyn",
    description: "Brooklyn Navy Yard, May 18th-21st 2023.",
    alt: "The Other Art Fair- Brooklyn. Brooklyn Navy Yard, May 2023",
  },
  {
    image: i2,
    title: "TIME MACHINE solo show",
    description: "4-15 Feb 2025. 50 Buckingham St, Surry Hills, Australia",
    alt: "TIME MACHINE- 4-15th Feb 2025",
  },
  {
    image: i3,
    title: "Los Angeles 5025",
    description: "Oil on linen. 122cm x 152cm. 2024",
    alt: "Los Angeles 5025. Oil on linen. 122cm x 152cm. 2024",
  },
  {
    image: i4,
    title: "Strange Paradise III",
    description: "Oil & acrylic on linen. 150cm x 150cm. 2022",
    alt: "Strange Paradise III. Oil & acrylic on linen. 150cm x 150cm. 2022",
  },
  {
    image: i5,
    title: "Strange Paradise III (detail)",
    alt: "Strange Paradise III (detail)",
  },
  {
    image: i6,
    title: "A Day in Woollahra II",
    description: "Oil & acrylic on linen. 76cm x 76cm. 2022",
    alt: "A Day in Woollahra II. Oil & acrylic on linen. 76cm x 76cm. 2022. ",
  },
  {
    image: i7,
    title: "A Day in Woollahra II (detail)",
    alt: "A Day in Woollahra II (detail)",
  },
  {
    image: i8,
    title: "Paddington II",
    description: "Oil & acrylic on canvas. 140cm x 150cm. 2016",
    alt: "Paddington II. Oil & acrylic on canvas. 140cm x 150cm. 2016.",
  },
  {
    image: i9,
    title: "The Dinner Party",
    description: "Oil on linen. 45.5cm x 45.5cm. 2022-23",
    alt: "The Dinner Party. 45.5cm x 45.5cm. 2022-23.",
  },
  {
    image: i10,
    title: "New Yorkers- Summer",
    description: "Oil & acrylic on linen. 152cm x 152cm. 2023",
    alt: "New Yorkers- Summer. Oil & acrylic on linen. 152cm x 152cm. 2023. ",
  },
  {
    image: i11,
    title: "Camp Cove IV & Camp Cove IV (15 minutes later)",
    description: "Oil & acrylic on linen. 61cm x 71cm. 2021",
    alt: "Camp Cove IV & Camp Cove IV (15 minutes later). Oil & acrylic on linen. 61cm x 71cm. 2021.",
  },
  {
    image: i12,
    title: "Family Dinner",
    description: "Oil on linen. 76cm x 76cm. 2023",
    alt: "Family Dinner. Oil on linen. 76cm x 76cm. 2023.",
  },
  {
    image: i13,
    title: "City of Angels",
    description: "Oil & acrylic on linen. 150cm x 150cm. 2022",
    alt: "City of Angels. Oil & acrylic on linen. 150cm x 150cm. 2022.",
  },
  {
    image: i14,
    title: "City of Angels (detail)",

    alt: "City of Angels- detail",
  },
  {
    image: i15,
    title: "The Film Set II",
    description: "Oil & acrylic on linen. 102cm x 122cm. 2024",
    alt: "The Film Set II. Oil & acrylic on linen. 102cm x 122cm. 2024.",
  },
  {
    image: i16,
    title: "Sydney Studio- November 2023",
    description: "Recent paintings for TOAF in Brooklyn",
    alt: "Sydney Studio- November 2023. Recent paintings for TOAF in Brooklyn.",
  },
  {
    image: i17,
    title: "The Restaurant",
    description: "Oil & acrylic on linen. 61cm x 71cm. 2022",
    alt: "The Restaurant. Oil & acrylic on linen. 61cm x 71cm. 2022.",
  },
  {
    image: i18,
    title: "The Other Art Fair- Brooklyn",
    description: "Brooklyn ZeroSpace, May 16th-19th 2024.",
    alt: "The Other Art Fair- Brooklyn. ZeroSpace, May 2024",
  },
  {
    image: i19,
    title: "East Redfern",
    description: "Oil & acrylic on linen. 61cm x 71cm. 2021",
    alt: "East Redfern. Oil & acrylic on linen. 61cm x 71cm. 2021.",
  },
  {
    image: i20,
    title: "Manly III",
    description: "Oil & acrylic on linen. 137cm x 152cm. 2021",
    alt: "Manly III. Oil & acrylic on linen. 137cm x 152cm. 2021.",
  },
  {
    image: i21,
    title: "Untitled (Gym)",
    description: "Oil & acrylic on linen. 25cm x 25cm. 2024",
    alt: "Untitled (Gym). Oil & acrylic on linen. 25cm x 25cm. 2024.",
  },
  {
    image: i22,
    title: "Feeding Time",
    description: "Oil on linen. 22.5cm x 20cm. 2021",
    alt: "Feeding Time. Oil on linen. 22.5cm x 20cm. 2021.",
  },
  {
    image: i23,
    title: "Crying Child",
    description: "Oil on linen. 22.5cm x 20cm. 2021",
    alt: "Crying Child. Oil on linen. 22.5cm x 20cm. 2021.",
  },
  {
    image: i24,
    title: "Inflatable Lips and a Popsicle",
    description: "Oil & acrylic on linen. 76cm x 76cm. 2022",
    alt: "Inflatable Lips and a Popsicle. Oil & acrylic on linen. 76cm x 76cm. 2022.",
  },
  {
    image: i25,
    title: "Bondi Summers V",
    description: "Oil & acrylic on canvas. 140cm x 150cm. 2016",
    alt: "Bondi Summers V. Oil & acrylic on canvas. 140cm x 150cm. 2016.",
  },
  {
    image: i26,
    title: "Paris- A Moveable Feast",
    description: "Oil & acrylic on linen. 71cm x 71cm. 2024",
    alt: "Paris- A Moveable Feast. Oil & acrylic on linen. 71cm x 71cm. 2024.",
  },
  {
    image: i27,
    title: "Washington Square Park NYC",
    description: "Oil & acrylic on linen. 91cm x 91cm. 2024",
    alt: "Washington Square Park NYC. Oil & acrylic on linen. 91cm x 91cm. 2024.",
  },
  {
    image: i28,
    title: "Surry Hills",
    description: "Oil & acrylic on linen. 137cm x 152cm. 2021",
    alt: "Surry Hills. Oil & acrylic on linen. 137cm x 152cm. 2021.",
  },
  {
    image: i29,
    title: "Paddington VII",
    description: "Oil & acrylic on linen. 137cm x 152cm. 2021",
    alt: "Paddington VII. Oil & acrylic on linen. 137cm x 152cm. 2021.",
  },
  {
    image: i30,
    title: "Tokyo 2174",
    description: "Oil on linen. 51cm x 61cm. 2024",
    alt: "Tokyo 2174. Oil on linen. 51cm x 61cm. 2024.",
  },
  {
    image: i31,
    title: "Pegasus & Hulk at the Beach",
    description: "Oil & acrylic on canvas. 140cm x 150cm. 2016",
    alt: "Pegasus & Hulk at the Beach. Oil & acrylic on canvas. 140cm x 150cm. 2016.",
  },
  {
    image: i32,
    title: "The Optimist II",
    description: "Oil & acrylic on linen. 122cm x 102cm. 2023/2024",
    alt: "The Optimist II. Oil & acrylic on linen. 122cm x 102cm. 2023/2024.",
  },
  {
    image: i33,
    title: "New Yorkers- Winter",
    description: "Oil & acrylic on linen. 152cm x 152cm. 2023",
    alt: "New Yorkers- Winter. Oil & acrylic on linen. 152cm x 152cm. 2023.",
  },
  {
    image: i34,
    title: "Wooster & Prince",
    description: "Oil & acrylic on linen. 41cm x 36cm. 2023",
    alt: "Wooster & Prince. Oil & acrylic on linen. 41cm x 36cm. 2023.",
  },
  {
    image: i35,
    title: "A Day in Malibu",
    description: "Oil & acrylic on linen. 152cm x 152cm. 2024",
    alt: "A Day in Malibu. Oil & acrylic on linen. 152cm x 152cm. 2024.",
  },
  {
    image: i36,
    title: "Euro Summer",
    description: "Oil & acrylic on linen. 51cm x 61cm. 2024",
    alt: "Euro Summer. Oil & acrylic on linen. 51cm x 61cm. 2024.",
  },
  {
    image: i37,
    title: "Thredbo IV",
    description: "Oil & acrylic on linen. 137cm x 152cm. 2023",
    alt: "Thredbo IV. Oil & acrylic on linen. 137cm x 152cm. 2023.",
  },
  {
    image: i38,
    title: "Soho 2053",
    description: "Oil & acrylic on linen. 152cm x 152cm. 2023",
    alt: "Soho 2053. Oil & acrylic on linen. 152cm x 152cm. 2023",
  },
  {
    image: i39,
    title: "Home is where the heart is",
    description: "Oil & acrylic on linen. 183cm x 183cm. 2024/2025",
    alt: "Home is where the heart is. Oil & acrylic on linen. 183cm x 183cm. 2024/2025.",
  },
  {
    image: i40,
    title: "Kutti Beach II",
    description: "Oil & acrylic on linen. 61cm x 71cm. 2021",
    alt: "Kutti Beach II. Oil & acrylic on linen. 61cm x 71cm. 2021.",
  },
  {
    image: i41,
    title: "Solo Show: Eastern Suburbs",
    description: "Saint Cloche, Sydney, 2017",
    alt: "Solo Show- Eastern Suburbs at Saint Cloche, Sydney, 2017",
  },
  {
    image: i42,
    title: "The Optimist",
    description: "Limited edition prints available in the online shop",
    alt: "The Optimist- Limited Edition print available in the online shop.",
  },
  {
    image: i43,
    title: "October",
    description: "Oil & acrylic on linen. 150cm x 150cm. 2022",
    alt: "October. Oil & acrylic on linen. 150cm x 150cm. 2022.",
  },
  {
    image: i44,
    title: "Fort Greene- Brooklyn",
    description: "Oil & acrylic on linen. 122cm x 152cm. 2024",
    alt: "Fort Greene- Brooklyn. Oil & acrylic on linen. 122cm x 152cm. 2024.",
  },
  {
    image: i45,
    title: "Chelsea- New York",
    description: "Oil & acrylic on linen. 101cm x 101cm. 2023",
    alt: "Chelsea- New York. Oil & acrylic on linen. 101cm x 101cm. 2023.",
  },
  // { image: blank, alt: "blank" },
];

export default imageCollection;
